import { InfoIcon } from '@grille/components/icons'
import { Color } from '@grille/constants/colors'
import Tooltip from '@mui/material/Tooltip'
import { useRef, useState } from 'react'
import cx from 'classnames'
import styles from './disclaimer-tooltip.module.scss'
import { disclaimerTexts } from '@grille/constants/disclaimer'
import DisclaimerTooltipContent from './disclaimer-tooltip-content'
import { DisclaimerType } from '@grille/types/disclaimer'
import { NamedIconProps } from '../icon/types'
import { MAX_ZINDEX } from '@grille/constants/zindex'

const DisclaimerTooltip = ({ disclaimerType, iconProps = {} }: DisclaimerTooltipProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const selfRef = useRef<HTMLDivElement>(null)
  let text = disclaimerTexts[disclaimerType]

  if (!text) {
    return null
  }

  return (
    <Tooltip
      componentsProps={{
        arrow: {
          sx: {
            fontSize: '22px',
            color: '#EFEFEF',
            '&:before': {
              border: '2px solid #A7A7A7'
            }
          }
        },
        popper: {
          sx: {
            zIndex: MAX_ZINDEX
          }
        },
        tooltip: {
          sx: {
            bgcolor: '#EFEFEF',
            borderRadius: '8px',
            borderWidth: '2px',
            borderColor: '#A7A7A7',
            paddingBottom: '12px',
            '&.MuiTooltip-tooltip': {
              '&.MuiTooltip-tooltipPlacementBottom': {
                marginTop: '14px'
              },
              '&.MuiTooltip-tooltipPlacementTop': {
                marginBottom: '14px'
              },
              '&.MuiTooltip-tooltipPlacementLeft': {
                marginRight: '14px'
              },
              '&.MuiTooltip-tooltipPlacementRight': {
                marginLeft: '14px'
              }
            }
          }
        }
      }}
      title={
        <DisclaimerTooltipContent
          text={text}
          onClickOutside={(event) => {
            if (event && !selfRef.current?.contains(event.target as Node)) {
              setOpen(false)
            }
          }}
        />
      }
      arrow
      open={open}
    >
      <div
        ref={selfRef}
        className={cx(styles['d-container'])}
        onClick={() => {
          setOpen((prev) => !prev)
        }}
      >
        <InfoIcon color={Color.gray400} height='16' {...iconProps} />
      </div>
    </Tooltip>
  )
}

export default DisclaimerTooltip

export interface DisclaimerTooltipProps {
  disclaimerType: DisclaimerType
  iconProps?: NamedIconProps
}
