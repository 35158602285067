import { useContext, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import MarketplaceUSP from './cars-for-sale/marketplace-usp'
import { PageContext, useUpdatePageCtx } from '../utils/contexts/pageContext'
import { useCollectStoreAdData } from '../utils/hooks/useCollectStoreAdData'
import { Panels } from './navbar/types'
import isEmpty from 'lodash/isEmpty'
import {
  isRenderRedbookCopyrightComponent,
  renderDealerLicence
} from './cars-for-sale/listings-single/utility'
import { isGamTakeOverPage } from '@grille/utils/functions/miscellaneous'
import useResponsive from '../utils/hooks/useResponsive'
import DealerLicence from './footer/dealer-licence'
import Roofrack from './roofrack'
import CompareBucket from './compareBucket'
import styles from './layout.module.scss'
import useIsTabletScreen from '../utils/hooks/useIsTabletScreen'
import { useDynamicRoutePath } from '@grille/utils/hooks/useDynamicRoutePath'

/** Following component can be lazyLoaded later
 * temporary revert for Layout.js file of https://github.com/caradvice/drive-grille/pull/4244/
 * see full details on change made in the pr at https://github.com/caradvice/drive-grille/blob/e1023e7de59cce434ecbfce99a88e6276a1849f2/components/Layout.js
 */
import RedbookCopyright from './footer/redbook-copyright'
import SubscribeToNewsletterContent from '../components/subscribe-to-newsletter/subscribe-to-newsletter-content'
import Footer from './footer'

export default function Layout({
  data,
  children,
  noMargin = false,
  customContainerClasses = '',
  shouldRenderRedbookCopyright,
  roofrackWrapperClassname = '',
  adId,
  hideAds = false, //used for hide show ads through styles
  isLandingGuide = false,
  removeAds = false,
  homepage = false,
  tempStickyRoofrack = false, // only applies to @screen md, and will be sticky only once per pageload
  renderMarketPlaceUSPBanner = {
    showNusedBanner: false,
    fromPage: ''
  },
  templateName = '',
  isAppRoute = false
}) {
  const {
    footerMenusColOne,
    footerMenusColTwo,
    footerMenusColThree,
    footerMenusColFour,
    footerMenusBottom,
    adWrapperClassName,
    adClassName,
    extraAdId,
    extraAdClassName,
    mobilePersistStickyHeader
  } = data || {}

  const { pageStore } = useContext(PageContext) ?? {}
  const { updateStore } = useUpdatePageCtx()
  const [adStatus, setAdStatus] = useState({ adElRefCurrent: null, isLoaded: false })
  const [showFullDescription, setShowFullDescription] = useState(false)
  const bodyContainerRef = useRef(null)
  const isRenderRedbookCopyright = isRenderRedbookCopyrightComponent(
    pageStore?.pageInfo?.pathname,
    shouldRenderRedbookCopyright
  )
  const isRenderDealerLicence = renderDealerLicence(pageStore?.pageInfo?.pathname)
  const { isMobileScreen } = useResponsive()
  const { isTablet } = useIsTabletScreen() //this includes mobile screen. simply non-desktop

  const shouldRenderRoofrack =
    ((homepage && isTablet) || !homepage) && !(isLandingGuide && !isTablet)
  const pageRouter = useDynamicRoutePath()
  const adBgColor = pageStore?.gam_oop_pos0?.img?.['data-bgcolor'] ?? false
  const adType = pageStore?.gam_oop_pos0?.img?.['data-adtype'] ?? false
  const { openedPanel } = pageStore || {}
  const dynamicStyles = () => {
    const styles = {}
    if (extraAdId || openedPanel) {
      if (adBgColor) {
        styles.background = `${adBgColor}`
      }
    }

    return isEmpty(styles) ? null : styles
  }

  const skipGamTakeover = !(!isMobileScreen && pageRouter && isGamTakeOverPage(pageRouter)) // do not skip if current page qualifies gam takeover

  const gamInfo = {
    gamId: 'gam_oop_pos0',
    dataToCollect: [{ name: 'img', pos: 0 }],
    skip: skipGamTakeover
  }

  // collect gamPos0data if current only page qualifies, utilizing skip
  useCollectStoreAdData(gamInfo, adStatus)
  const hideRoofRackAdPageStore = pageStore?.hideRoofrackAd ?? false

  return (
    <>
      <div
        ref={bodyContainerRef}
        id='body-container'
        className={cx(
          styles['body__container'],
          { 'gam-takeover': adBgColor },
          { 'gam-sticky': adType === 'sticky' },
          {
            [styles['body__container__templates--cfs__single']]: templateName === 'cfsSingleListing'
          }
        )}
        style={dynamicStyles()}
        onClick={() => {
          openedPanel === Panels.sideMenu && updateStore('openedPanel', null)
        }}
      >
        {renderMarketPlaceUSPBanner?.showNusedBanner ? (
          <MarketplaceUSP fromPage={renderMarketPlaceUSPBanner?.fromPage} />
        ) : null}
        {!removeAds ? (
          <Roofrack
            shouldRenderRoofrack={shouldRenderRoofrack}
            openedPanel={openedPanel}
            adWrapperClassName={
              homepage && !isMobileScreen ? 'h-0 lg:w-full lg:bg-transparent' : adWrapperClassName
            }
            homepage={homepage}
            adClassName={adClassName}
            extraAdClassName={extraAdClassName}
            extraAdId={extraAdId}
            hideAds={hideAds}
            hideRoofRackAdPageStore={hideRoofRackAdPageStore}
            adStatus={adStatus}
            setAdStatus={setAdStatus}
            tempStickyRoofrack={tempStickyRoofrack}
            adId={adId ?? null}
            adBgColor={adBgColor}
            mobilePersistStickyHeader={mobilePersistStickyHeader}
            wrapperClassname={roofrackWrapperClassname}
          />
        ) : null}
        <div
          className={cx(
            { 'container mx-auto': !noMargin & !customContainerClasses },
            customContainerClasses,
            { 'gam-takeover-body': adBgColor && extraAdId }
          )}
        >
          {children}
          {isRenderRedbookCopyright ? (
            <RedbookCopyright
              showFullDescription={showFullDescription}
              setShowFullDescription={setShowFullDescription}
            />
          ) : null}
          {isRenderDealerLicence ? <DealerLicence /> : null}
        </div>

        <div className={cx(styles['drive-layout__footer-wrapper'], 'footer-wrapper')}>
          <SubscribeToNewsletterContent />
          <Footer
            footerMenusColOne={footerMenusColOne}
            footerMenusColTwo={footerMenusColTwo}
            footerMenusColThree={footerMenusColThree}
            footerMenusColFour={footerMenusColFour}
            footerMenusBottom={footerMenusBottom}
            showFullDescription={showFullDescription}
            setShowFullDescription={setShowFullDescription}
            isAppRoute={isAppRoute}
          />
        </div>

        <CompareBucket />
      </div>
    </>
  )
}

Layout.propTypes = {
  adId: PropTypes.string,
  shouldRenderRedbookCopyright: PropTypes.bool
}
