import { gql } from '@apollo/client'
import { DealerListingPriceHistoryFields } from '../../fragments/price-history'

export const GET_CARS_FOR_SALE_DEALER_LISTING = gql`
  query GET_CARS_FOR_SALE_DEALER_LISTING($id: BigInt!) {
    dealerListing: DealerListing(id: $id) {
      id
      isSold
      isHidden
      colour: exteriorColour
      third_party_stockid: dataProviderStockId
      comments
      plate
      seatCapacity
      vehicleVfactsPrice: vfactsPricingSegment
      vehicleVfactsClass: vfactsClass
      vehicleVfactsSegment: vfactsSegment
      badgeDescription
      badgeSecondaryDescription
      bodyStyleDescription
      bodyConfigDescription
      vehicleKey: redbookVehicleKey

      isWithinWarranty
      warrantyKm
      warrantyExpiryKm
      warrantyExpiryDate

      regionId
      region: Region {
        regionName: name
        regionSlug: nameSlug
        regionId: id
        stateSlug: stateSlug
        state: state
      }

      makeName: makeDescription
      makeSlug: makeSlug
      modelName: familyDescription
      modelSlug: familySlug
      vehicleBadgeDescription: badgeDescription
      dealerId
      stockNumber: dataProviderStockId
      odometer
      vin
      createdAt
      priceExcludingGovtCharges: priceEgc
      priceDriveAway: priceIgc
      priceGauge: PriceRating {
        priceRating
        marketPriceRangeMin
        marketPriceRangeMax
        marketPricePercentile
      }
      priceGauge: PriceRating{
          priceRating
      }
      ${DealerListingPriceHistoryFields}
      year
      listType: stockType
      odometer
      variant: description
      image: mainImage {
        id
        url
        publicId
        cloudName
      }
      fuelType: fuelTypeDescription
      body: bodyType
      images: DealerListingImages {
        id
        srcUrl: url
        publicId
        cloudName
      }
      redbookVehicle: RedbookVehicle {
        vehicleDoorNum: doorNum
        vehicleKey
        vehicleBadge: attributes {
          shortDescription
        }
        variantUuid: uuid
        vehicleDescription: description
        vehicleFamilyCode: familyCode
        vehicleMakeCode: makeCode
        vehicleModel: RedbookFamily {
          description
          uuid
        }
        engineSize
        transmission: gearTypeDescription
        vehicleModelCode: familyCode
        vehicleSeriesModelYear: seriesModelYear
        vehicleYearGroup: yearGroup
        vehicleMake: RedbookMake {
          description
          uuid
        }
        redbookPhotos: photos {
          id
          externalId
          redbookPhotoId
          srcUrl: url
          redbookPhoto {
            view
          }
        }
        kerbWeight
        vehicleLimitedEdition: limitedEdition
        vehicleBuildCountryOriginDescription: buildCountryOriginDescription
        vehicleSeriesPublic: seriesPublic
        manufacturerBodyStyle: manufacturerBodyStyle
        manufacturerBodyConfig: manufacturerBodyConfig
        engineTypeDescription: engineTypeDescription
        vehicleCombinedPower: combinedPower
        attributes {
          shortDescription
        }
      }
      dealer: Dealer {
        id
        name
        suburb
        state
        salesforceId
        region: Region {
          id
          state
          name
          nameSlug
          stateSlug
        }

        postcode
        phone
        email

        url: website
        isExcludingGvtCharge: allowEgc
        enableBranding
        dealerDepartment: DealerDepartments(where: { isActive: { eq: 1 }, type: { eq: Used } }) {
          id
          dealerId
          dealerLicence: licenseNumber
          phone: leadNotificationPhone
          email: leadNotificationEmailList
          isOnlineOnly
          locationLabel
        }
      }
    }
  }
`
