import { FunctionComponent } from 'react'
import MarketplaceUSPBanner from './banner'
import MarketplaceUSPPoster from './poster'
import {
  CARS_FOR_SALE_LANDING,
  CARS_FOR_SALE_RESULTS_PAGE,
  HOME_PAGE,
  SHOWROOM_BEST_CARS,
  SHOWROOM_LANDING,
  SHOWROOM_MAKE,
  SHOWROOM_MODEL
} from '@grille/constants/pages'
import NusedBanner from './nused-banner'

const MarketplaceUSP: FunctionComponent<MarketplaceUSPProps> = ({ isBanner = true, fromPage }) => {
  const usps: string[] = [
    'New & quality used cars',
    'Warranty',
    'No private sellers',
    'Cars available now'
  ]

  if (
    fromPage &&
    [HOME_PAGE, SHOWROOM_LANDING, SHOWROOM_MAKE, SHOWROOM_MODEL, SHOWROOM_BEST_CARS].includes(
      fromPage
    )
  ) {
    return <NusedBanner link={CARS_FOR_SALE_LANDING} />
  }
  if (fromPage === CARS_FOR_SALE_RESULTS_PAGE) {
    return <NusedBanner />
  } else if (isBanner) {
    return <MarketplaceUSPBanner usps={usps} />
  } else {
    return <MarketplaceUSPPoster usps={usps} />
  }
}

type MarketplaceUSPProps = {
  isBanner?: boolean
  fromPage?: string
}

export default MarketplaceUSP
