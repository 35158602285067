export const MRLP =
  "Manufacturer's Recommended List Price (MRLP) excludes all on-road costs such as registration, stamp duty, luxury car tax, compulsory third party (CTP) insurance and dealer delivery charges."

export const EXCL_GOVT_CHARGES =
  'Excludes Government Charges excludes costs such as registration or transfer fees, stamp duty, dealer delivery and other government charges, which varies from state to state.'

export const DRIVE_AWAY_PRICE =
  'Drive Away Price includes dealer and government costs such as registration, stamp duty and dealer delivery. Car insurance costs such as compulsory third party (CTP) are not included unless stated by the dealer.'

export const disclaimerTexts = {
  MRLP,
  EXCL_GOVT_CHARGES,
  DRIVE_AWAY_PRICE
}
