import { Filters, HashFilters, SlugFilters } from '@grille/types/listings'

export const CFS_SEARCH_BASE_PATH: string = '/cars-for-sale/search/'
export const CFS_CUSTOM_FACADE_BASE_PATH: string = '/cars-for-sale/featured/'

export const DEFAULT_CFS_HASH_FILTERS: HashFilters = {
  nused: null,
  listingType: null,
  bodyType: null,
  badge: null,
  year: null,
  price: null,
  priceRating: null,
  transmission: null,
  odometer: null,
  fuelType: null,
  doors: null,
  seats: null,
  lifeStyle: null
}

export const DEFAULT_CFS_SLUG_FILTERS: SlugFilters = {
  listingType: null, //multiselect
  state: null, //location
  region: null,
  make: null,
  model: null
}

export const DEFAULT_CFS_FILTERS: Filters = {
  ...DEFAULT_CFS_SLUG_FILTERS,
  ...DEFAULT_CFS_HASH_FILTERS
}

export default CFS_SEARCH_BASE_PATH

export const LISTING_TYPE_ORDER: Record<string, number> = {
  new: 1,
  demo: 2,
  used: 3,
  'available-to-order': 4
}
export const FUEL_TYPE_ORDER: Record<string, number> = {
  electric: 1,
  hybrid: 2,
  patrol: 3,
  diesel: 4
}

export const NEW_LISTING_TYPE = {
  label: 'New',
  value: 'new'
}

export const DEMO_LISTING_TYPE = {
  label: 'Demo',
  value: 'demo'
}

export const USED_LISTING_TYPE = {
  label: 'Used',
  value: 'used'
}

export const NEW_LISTING_TYPES = [NEW_LISTING_TYPE.value, DEMO_LISTING_TYPE.value]
export const OLD_LISTING_TYPES = [USED_LISTING_TYPE.value]
export const GHOST_LISTING_TYPE = {
  label: 'Available to order',
  value: 'available-to-order'
}

export const LISTING_TYPE_OPTIONS = [
  NEW_LISTING_TYPE,
  DEMO_LISTING_TYPE,
  USED_LISTING_TYPE,
  GHOST_LISTING_TYPE
]
