import { PriceChange } from '@grille/types/singleListing'
import { CFSListingDealer } from './../../../types/singleListing'
import { getPriceDropInfo } from '../cars-for-sale/price-history'
import { TDealerListingDealer } from '@grille/components/templates/cars-for-sale/search/types'
import { DisclaimerType } from '@grille/types/disclaimer'

/**
 * Get the listings price info.
 * Does not work for ghost listing
 * @param {Object} dealer Dealer.
 * @param {Number} priceIgc Price Drive Away.
 * @param {Number} priceEgc Price Excluding Govt Charges.
 *
 */
export const getListingPriceInfo = (
  dealer: Partial<CFSListingDealer | TDealerListingDealer>,
  priceIgc: number,
  priceEgc: number,
  priceHistory: PriceChange[]
): {
  formattedPrice: string
  priceUnit: string
  price: number
  isPriceDrop: boolean
  originalPriceValue: number | null
  shouldUsePriceEGC: boolean
  disclaimerType: DisclaimerType
} => {
  const shouldUsePriceEGC: boolean = !!dealer?.isExcludingGvtCharge && priceEgc !== 0
  const priceDrop = getPriceDropInfo(priceHistory, shouldUsePriceEGC)
  let priceUnit: string
  let disclaimerType: DisclaimerType
  if (dealer?.isExcludingGvtCharge && priceEgc !== 0) {
    priceUnit = '*Excl. Govt. Charges'
    disclaimerType = 'EXCL_GOVT_CHARGES'
  } else {
    priceUnit = 'Drive Away'
    disclaimerType = 'DRIVE_AWAY_PRICE'
  }

  return {
    formattedPrice:
      dealer?.isExcludingGvtCharge && priceEgc !== 0
        ? `$${Number(priceEgc).toLocaleString() || ''}`
        : `$${Number(priceIgc).toLocaleString() || ''}`,
    priceUnit: priceUnit,
    disclaimerType: disclaimerType,
    price: dealer?.isExcludingGvtCharge && priceEgc !== 0 ? priceEgc : priceIgc,
    isPriceDrop: priceDrop.isPriceDrop,
    originalPriceValue: priceDrop.originalPriceValue,
    shouldUsePriceEGC
  }
}

export const getGhostListingPriceInfo = (
  price: number
): {
  formattedPrice: string
  priceUnit: string
  price: number
  disclaimerType: DisclaimerType
} => {
  return {
    formattedPrice: `$${Number(price).toLocaleString() || ''}`,
    priceUnit: 'MRLP*',
    price: price,
    disclaimerType: 'MRLP'
  }
}
