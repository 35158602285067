import cx from 'classnames'
import styles from './marketplaceUspBanner.module.scss'
import { Color } from '@grille/constants/colors'
import UspItem from '../usp-item'

function MarketplaceUSPBanner({ usps }: Props) {
  return (
    <div className={cx(styles['d-marketplace-usp-banner'])}>
      <div className={cx(styles['d-marketplace-usp-banner__container'])}>
        <div className={cx(styles['d-marketplace-usp-banner__wrapper'])}>
          {usps.map((usp, index) => (
            <UspItem
              uspItemValue={usp}
              key={index}
              iconColor={Color.white}
              className={cx(styles['d-marketplace-usp-banner__item'])}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

type Props = { usps: string[]; className?: string }

export default MarketplaceUSPBanner
