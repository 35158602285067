import { InfoIcon } from '@grille/components/icons'
import { Color } from '@grille/constants/colors'
import useClickOutside from '@grille/utils/hooks/useClickOutside'
import { FC, useRef } from 'react'
import cx from 'classnames'
import styles from './disclaimer-tooltip-content.module.scss'

const DisclaimerTooltipContent: FC<Props> = ({ text, onClickOutside }) => {
  const selfRef = useRef<HTMLDivElement>(null)
  useClickOutside({ onClickOutside, observeRef: selfRef })

  return (
    <div ref={selfRef} className={cx(styles['d-container'])}>
      <InfoIcon className={cx(styles['d-container__icon'])} color={Color.black} height='24' />
      <p className={cx(styles['d-container__text'])}>{text}</p>
    </div>
  )
}

export default DisclaimerTooltipContent

type Props = {
  text: string
  onClickOutside?: (event?: MouseEvent) => void
}
