import { gql } from '@apollo/client'
import { PER_PAGE_FIRST_LISTING } from '../../../utils/functions/pagination'

/**
 * Get the cars for sale from the Cars For Sale GraphQL API.
 */
export const DEALER_LISTINGS = gql`

  query DEALER_LISTINGS(
    $where: WhereOptionsDealerListing = {}, 
    $pageNo: Int! = 0, 
    $sort: SortInput = { order: [["recommended", "DESC"]] }
    $priceHistory: Boolean = false
  ) {
    listings: DealerListings(where: $where
    , paginate: { page: $pageNo, pageSize: ${PER_PAGE_FIRST_LISTING} },
     sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        currentPage
        pageItemCount
      }
      results {
        id
        colour: exteriorColour
        third_party_stockid: dataProviderStockId
        vehicleKey: redbookVehicleKey
        listType: stockType
        year
        priceExcludingGovtCharges: priceEgc
        priceDriveAway: priceIgc
        priceGauge: PriceRating{
          priceRating
        }
        priceHistory: History(where: { changed: one, fields: [price_igc, price_egc] }) @include(if: $priceHistory) {
          id
          oldValues
          newValues
          createdAt
        }
        odometer
        isWithinWarranty
        createdAt
        dealerId
        body: bodyType
        seatCapacity
        fuelType: fuelTypeDescription
        variant: description
        specs: RedbookVehicle {
          variantUuid: uuid
          vehicleCombinedPower: combinedPower
          vehicleDoorNum: doorNum
          vehicleDescription: description
          id
          vehicleKey
          fuelCombined
          fuelType: fuelTypeDescription
          fuelTypeName: fuelType {
              name
          }
          transmission: gearTypeDescription
          engineSize: engineSize
          attributes {
              id
              shortDescription
          }
          make: RedbookMake {
            description
            uuid
          }
          model: RedbookFamily {
            description
            uuid
          }
          redbookPhotos: photos {
            id
            externalId
            redbookPhotoId
            srcUrl: url
            redbookPhoto {
              view
            }
          }
        }
        image: mainImage {
          id
          srcUrl: url
          publicId
          cloudName
        }
        images: DealerListingImages {
          id
          srcUrl: url
          publicId
          cloudName
        }
        makeName : makeDescription
        modelName: familyDescription
        region: Region {
          regionName: name
          regionSlug: nameSlug
          regionId: id
          state
          stateSlug
        }
        dealer: Dealer {
          id
          name
          suburb
          state
          postcode
          phone
          email
          isExcludingGvtCharge: allowEgc
          dealerDepartment: DealerDepartments(where: { isActive: { eq: 1 }, type: { eq: Used } }) {
            id
            phone: leadNotificationPhone
            email: leadNotificationEmailList
            isOnlineOnly
            locationLabel
          }
        }
      }
    }

  }
  
`

export const GHOST_LISTINGS = gql`
  query GHOST_LISTINGS(
    $where: WhereOptionsGhostListing
    $pageNo: Int!
    $sort: SortInput
    $pageSize: Int! = 6
  ) {
    ghostListings: GhostListings(
      where: $where
      paginate: { page: $pageNo, pageSize: $pageSize }
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        currentPage
        pageItemCount
      }
      results {
        id
        colorCount: numUniquePrimaryColours
        vehicleKey: redbookVehicleKey
        year: yearGroup
        price: newPrice
        priceExcludingGovtCharges: newPrice
        priceDriveAway: newPrice
        variant: description
        specs: RedbookVehicle {
          variantUuid: uuid
          id
          vehicleKey
          fuelCombined
          fuelType: fuelTypeDescription
          fuelTypeName: fuelType {
            name
          }
          transmission: gearTypeDescription
          engineSize: engineSize
          attributes {
            id
            shortDescription
          }
          make: RedbookMake {
            description
            uuid
          }
          model: RedbookFamily {
            description
            uuid
          }
          redbookPhotos: photos {
            id
            externalId
            redbookPhotoId
            srcUrl: url
            redbookPhoto {
              view
            }
          }
        }
        mainImageId
        makeName: makeDescription
        modelName: familyDescription
      }
    }
  }
`
export const getGhostListingMultiPageQuery = (label: string) => {
  return gql`
    query GHOST_LISTINGS(
    $where: WhereOptionsGhostListing
    $pageNo: Int!
    $sort: SortInput
    $pageSize: Int! = 6
    ) {
      ${label}: GhostListings(
      where: $where
      paginate: { page: $pageNo, pageSize: $pageSize }
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        currentPage
        pageItemCount
      }
      results {
        id
        colorCount: numUniquePrimaryColours
        vehicleKey: redbookVehicleKey
        year: yearGroup
        price: newPrice
        priceExcludingGovtCharges: newPrice
        priceDriveAway: newPrice
        variant: description
        specs: RedbookVehicle {
          variantUuid: uuid
          id
          vehicleKey
          fuelCombined
          fuelType: fuelTypeDescription
          fuelTypeName: fuelType {
            name
          }
          transmission: gearTypeDescription
          engineSize: engineSize
          attributes {
            id
            shortDescription
          }
          make: RedbookMake {
            description
            uuid
          }
          model: RedbookFamily {
            description
            uuid
          }
          redbookPhotos: photos {
            id
            externalId
            redbookPhotoId
            srcUrl: url
            redbookPhoto {
              view
            }
          }
        }
        mainImageId
        makeName: makeDescription
        modelName: familyDescription
      }
    }
    }
  `
}

export const DEALER_LISTINGS_META = gql`
  query DEALER_LISTINGS_META(
    $where: WhereOptionsDealerListing = {}, 
    $pageNo: Int! = 0, 
    $sort: SortInput = { order: [["recommended", "DESC"]] }
  ) {
    listingsPageInfo: DealerListings(where: $where
    , paginate: { page: $pageNo, pageSize: ${PER_PAGE_FIRST_LISTING} },
     sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        currentPage
        pageItemCount
      }
    }
  }
`
