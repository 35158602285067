export const STATIC_PAGES_COUNT = 1
export const LISTINGS_STATIC_PAGES_COUNT = 1
export const OUR_JOURNALISTS_PAGE = '/about-drive/our-journalists/'
export const OUR_TERMS_AND_CONDITIONS_PAGE = '/about-drive/terms-conditions/'
export const OUR_PRIVACY_POLICY_PAGE = '/about-drive/privacy-policy/'
export const ABOUT_NUSED_PAGE = '/cars-for-sale/about-nused/'
export const CARS_FOR_SALE_LANDING = '/cars-for-sale/'
export const DRIVES_TOP_5_LANDING_PAGE = 'top-5'

export const CARS_FOR_SALE_LISTING_SINGLE_PAGE = 'CARS_FOR_SALE_LISTING_SINGLE_PAGE'
export const CARS_FOR_SALE_RESULTS_PAGE = 'CARS_FOR_SALE_RESULTS_PAGE'
export const HOME_PAGE = 'HOME'
export const SHOWROOM_LANDING = 'SHOWROOM_LANDING'
export const SHOWROOM_MAKE = 'SHOWROOM_MAKE'
export const SHOWROOM_MODEL = 'SHOWROOM_MODEL'
export const SHOWROOM_BEST_CARS = 'SHOWROOM_BEST_CARS'
export const CARS_FOR_SALE_LANDING_PAGE = 'CARS_FOR_SALE_LANDING_PAGE'
export const CARS_FOR_SALE_ABOUT_NUSED = 'CARS_FOR_SALE_ABOUT_NUSED'
export const CARS_FOR_SALE_ARCHIVES_PAGE = 'CARS_FOR_SALE_ARCHIVES_PAGE'
export const CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE = 'CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE'
export const CARS_FOR_SALE_LISTINGS_CARD = 'CARS_FOR_SALE_LISTINGS_CARD'
export const SHOWROOMS_MODEL_LANDING_PAGE = 'SHOWROOMS_MODEL_LANDING_PAGE'
export const REVIEWS_PAGE = 'REVIEWS_PAGE'
export const DCOTY_SINGLE_PAGE = 'DCOTY_SINGLE_PAGE'
export const COMPARE_SPECS_LANDING_PAGE = 'COMPARE_SPECS_LANDING_PAGE'
export const ABOUT_DRIVE_PAGE = 'ABOUT_DRIVE_PAGE'

export const TAKE_OUR_PAGE_ROUTES = [
  '/',
  '/showrooms',
  '/showrooms/car-type/[slug]',
  '/showrooms/[make]',
  '/showrooms/[make]/[model]',
  '/showrooms/segment/[slug]',
  '/showrooms/segment/[slug]/all',
  '/showrooms/deals',
  '/showrooms/best-cars',
  '/showrooms/best-cars/[slug]',
  '/reviews-type/[slug]',
  '/guides/[hub]',
  '/guides/[hub]/[slug]'
]

export const LIVE_CHAT_EXCLUDE_ROUTES = [
  '/',
  '/cars-for-sale/whats-my-car-worth',
  '/sell-my-car',
  '/cars-for-sale',
  '/car-finder/',
  '/car-finder/[slug]',
  /* currently useDynamicRoute hook only supports catch all segment routes but not the optional ones with [[...slug]]
   * so to support the optional catch all include the non optional catch all segments routes even tho the folder name is [[...slug]]
   * see below to include the optional as non optional catch all segment
   */
  '/cars-for-sale/search/[...slugs]',
  '/cars-for-sale/search',
  '/cars-for-sale/car/[listingId]'
]

export const GREETING_EXCLUDE_ROUTES = [
  '/guides',
  '/guides/[hub]',
  '/guides/[hub]/[slug]',
  '/showrooms',
  '/showrooms/[make]',
  '/showrooms/[make]/[model]',
  '/showrooms/[make]/[model]/[variantUuid]',
  '/showrooms/car-type/[slug]',
  '/compare-specs',
  '/showrooms/deals',
  '/showrooms/best-cars',
  '/showrooms/best-cars/[slug]',
  '/car-finder/[slug]/[results]'
]

//Add pages on which we want to show nused variant of MARKETPLACE_LISTINGS_BLOCK
export const PAGES_WITH_MARKETPLACE_LISTINGS_BLOCK_NUSED_VARIANT = [
  CARS_FOR_SALE_ABOUT_NUSED,
  CARS_FOR_SALE_LANDING_PAGE
]

export const LIVE_CHAT_ALLOW_COUNTRY_CODE = ['AU']

export const GEO_APPLICABLE_STATES = ['NSW', 'QLD', 'VIC', 'WA']

export const TAG_ARTICLE_SLUG_25_YEARS = '25yearsofdrive'
export const TAG_URL_SLUG_25_YEARS = '25-years-of-drive'
export const TITLE_25_YEARS = '25 Years of Drive'

export const TAG_URL_SLUG_DRIVE_TV = 'drivetv'
export const TITLE_DRIVE_TV = 'Drive TV'
export const TITLE_DRIVE_TV_GHOST_PAGINATION = 'Drive TV Videos'

export const TITLE_NEWS_GHOST_PAGINATION = 'News Articles'
export const TITLE_CA_GHOST_PAGINATION = 'CarAdvice Articles'
