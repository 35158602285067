/* eslint-disable max-lines */
import { getImageData } from '@grille/utils/seo'
import { getSingleListingURL } from '@grille/utils/functions/slugs'

import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isNull from 'lodash/isNull'
import upperCase from 'lodash/upperCase'
import uniqBy from 'lodash/uniqBy'
import {
  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL,
  CARS_FOR_SALE_SECTION_CARDS
} from '@grille/constants/cars-for-sale'
import { LISTING_CARD_SPECS } from '@grille/queries/cars-for-sale/listings/get-drive-specs'
import { DEALER_LISTINGS } from '@grille/queries/cars-for-sale/listings/get-cars-for-sale'
import {
  getVehicleKeys,
  updateCFSPageTargetingAndDataLayer
} from '@grille/utils/functions/cars-for-sale'
import { getImageBySize } from '@grille/utils/functions/images'

import { getVariantPhotoByView, transformSpecs } from '@grille/utils/functions/variant'
import { DEALER_LISTINGS_COUNT } from '@grille/queries/cars-for-sale/listings/get-dealer-listing-count'
import { insertIf } from '@grille/utils/functions/miscellaneous'
import find from 'lodash/find'
import {
  GHOST_LISTING_TYPE,
  NEW_LISTING_TYPES
} from '@grille/components/cars-for-sale/listings/constants'
import capitalize from 'lodash/capitalize'
import { getBreadCrumb } from '@grille/utils/functions/cars-for-sale/search/breadcrumbs'

export * from './priceInfo'
/**
 * Transforms Cars For Sale Data
 * @param {Array} carsForSaleList
 *
 * @returns {Array} CarsForSaleList
 */
export const parseCarsForSaleData = (
  carsForSaleList,
  extra,
  cardsToShow = CARS_FOR_SALE_SECTION_CARDS
) => {
  if (isEmpty(carsForSaleList) && isEmpty(extra)) {
    return []
  }
  if (!isArray(carsForSaleList) && !isArray(extra)) {
    return []
  }

  const extraCars = extra ?? []
  const cars = carsForSaleList ?? []

  /** Unique cars list */
  const uniqueCarsList = uniqBy([...cars, ...extraCars], (listing) => listing?.listingId)
  const carsList = uniqueCarsList.map((listing) => {
    const { listingId, Dealer, redbook, priceEgc, priceDa, type, image } = listing || {}
    const { year } = redbook || {}
    const { allowEgc } = Dealer || {}
    const price = priceDa === 0 ? priceEgc : priceDa
    const nicename = `${listing.makeDescription} ${listing.familyDescription} ${year ?? ''}`
    const { url: sourceUrl, publicId, cloudName } = image ?? {}
    const cloudinary =
      publicId && cloudName
        ? {
            publicId,
            cloudName
          }
        : null
    const isEgcAllowed = !isNull(allowEgc) && priceDa === 0
    const location = getListingLocationLabel(Dealer, Dealer?.dealerDepartment?.[0])
    const uri = getSingleListingURL(listingId)

    return { nicename, location, type, price, sourceUrl, cloudinary, isEgcAllowed, uri, listingId }
  })
  return carsList?.length > cardsToShow ? carsList.slice(0, cardsToShow) : carsList
}

export const getListingLocationLabel = (dealer, dealerDepartment) => {
  if (!dealer || !dealerDepartment) {
    return ''
  }
  const { state } = dealer
  const { isOnlineOnly, locationLabel } = dealerDepartment

  if (isOnlineOnly === 1 && !isEmpty(locationLabel)) {
    return locationLabel
  } else if (!isEmpty(state)) {
    return state
  } else {
    return ''
  }
}

/**
 * Creates cars for sale models query from list of models
 * @param {Object} models models nodes
 * @returns {Array} List of query object for each model. Eg. [{makeSlug : 'volkswagen', familySlug : 'polo'}]
 */
export const getModelsQuery = (models) => {
  if (!models?.nodes || !isArray(models?.nodes)) {
    return []
  }
  return models?.nodes.map((model) => {
    const modelSlug = model?.slug ?? ''
    const makeSlug = model?.parentMake?.parentMake?.slug ?? undefined
    return { makeSlug: makeSlug, familySlug: modelSlug }
  })
}

/**
 * Creates cars for sale segmens query from list of segments
 * @param {Object} segment segment nodes
 * @returns {Array} List of query object for each segment.
 */
export const getSegmentQuery = (segment) => {
  if (!segment?.nodes || !isArray(segment?.nodes)) {
    return []
  }
  return segment?.nodes.map((segment) => {
    const { vfactsClass, vfactsSegment, vfactsPricingSegment } = segment?.segmentFields || {}
    return {
      vfactsClass: vfactsClass,
      vfactsSegment: vfactsSegment,
      vfactsPricingSegment: vfactsPricingSegment
    }
  })
}

/**
 * Create listing name by combining year, make, model and variant fields
 * @param {String} year Model year
 * @param {String} make Model make name
 * @param {String} model Model name
 * @param {Object} variantFields Variant fields for cretating variant name
 * @returns {String} Listing Name
 */
export const getListingName = (year, make, model, variantFields) => {
  const makeModelYear = [
    ...insertIf(year, [year]),
    ...insertIf(variantFields?.colour, [variantFields?.colour]),
    ...insertIf(make, [make?.description ?? make]),
    ...insertIf(model, [model?.description ?? model])
  ]

  const {
    badgeDescription,
    badgeSecondaryDescription,
    bodyStyleDescription,
    bodyConfigDescription,
    manufacturerBodyStyle,
    manufacturerBodyConfig
  } = variantFields || {}

  const nameParts = [makeModelYear.join(' ')]
  const bodyStyle = manufacturerBodyStyle || bodyStyleDescription
  const bodyConfig = manufacturerBodyConfig || bodyConfigDescription

  !isEmpty(badgeDescription) && nameParts.push(parseListingData(badgeDescription))
  !isEmpty(badgeSecondaryDescription) && nameParts.push(parseListingData(badgeSecondaryDescription))
  !isEmpty(bodyStyle) && nameParts.push(parseListingData(bodyStyle))
  !isEmpty(bodyConfig) && nameParts.push(parseListingData(bodyConfig))

  return nameParts.join(' ')
}

/**
 * @description destruct dealerListing and pass to getListingName()
 * @param dealerListing
 * @returns {String} Listing Name
 */
export const getNameFromListing = (dealerListing) => {
  const {
    year,
    redbookVehicle,
    badgeDescription,
    badgeSecondaryDescription,
    bodyStyleDescription,
    bodyConfigDescription
  } = dealerListing || {}

  const {
    vehicleMake,
    vehicleModel,
    manufacturerBodyStyle,
    manufacturerBodyConfig,
    vehicleMakeCode,
    vehicleFamilyCode
  } = redbookVehicle || {}

  const listingName = getListingName(year, vehicleMake, vehicleModel, {
    makeCode: vehicleMakeCode,
    familyCode: vehicleFamilyCode,
    badgeDescription,
    badgeSecondaryDescription,
    bodyStyleDescription,
    bodyConfigDescription,
    manufacturerBodyStyle,
    manufacturerBodyConfig
  })

  return listingName
}

/**
 * @description Parse dealerListing and return vehicleBadge + shortDescription from redbook vehicle data
 * @returns {string} vehicleBadge
 */
export const getVehicleBadgeAndShortDescription = (dealerListing) => {
  const { redbookVehicle } = dealerListing || {}
  const { vehicleBadge } = redbookVehicle || {}
  const shortDescription = vehicleBadge?.[0]?.shortDescription ?? ''
  const badge = dealerListing?.badgeDescription ?? ''

  return `${badge} ${shortDescription}`
}

/**
 * Parse incoming data from listings by making initial uppercase and trimming
 * @param {String | null} data listing data
 */
export const parseListingData = (data) => {
  if (!data || isEmpty(data)) {
    return ''
  }
  return data
    .split(' ')
    .map((str) => {
      return str.trim()
    })
    .join(' ')
}

/**
 * Parse seo meta for single listing page
 * @param {Object | null} dealerListing dealerListing data
 * @returns {Object} Parsed SEO meta data
 */
export const getParsedSeoMeta = (dealerListing, isGhostListing = false) => {
  if (!dealerListing) {
    dealerListing = {}
  }

  const dealerState = dealerListing.dealer?.state ? `${dealerListing.dealer?.state.trim()}` : ''
  let dealerSuburb = dealerListing.dealer?.suburb
    ? dealerListing.dealer?.suburb.replace(',', '').trim()
    : ''

  if (dealerListing.dealer?.state && dealerListing.dealer?.suburb) {
    dealerSuburb += ','
  }

  const capitalizedListType =
    dealerListing?.listType?.charAt(0).toUpperCase() + dealerListing?.listType?.slice(1)

  const listingName = getListingName(
    dealerListing.year || '',
    dealerListing.redbookVehicle?.vehicleMake?.description,
    dealerListing.redbookVehicle?.vehicleModel?.description,
    {
      colour: parseListingData(dealerListing.colour),
      makeCode: parseListingData(dealerListing.makeCode),
      familyCode: parseListingData(dealerListing.familyCode),
      badgeDescription: parseListingData(dealerListing.badgeDescription),
      badgeSecondaryDescription: parseListingData(dealerListing.badgeSecondaryDescription),
      bodyStyleDescription: parseListingData(dealerListing.bodyStyleDescription),
      bodyConfigDescription: parseListingData(dealerListing.bodyConfigDescription),
      manufacturerBodyStyle: parseListingData(dealerListing.manufacturerBodyStyle),
      manufacturerBodyConfig: parseListingData(dealerListing.manufacturerBodyConfig)
    }
  )

  const customTitle = `${parseListingData(
    isGhostListing ? 'New' : capitalizedListType
  )} ${listingName} ${isGhostListing ? 'Available to Order' : 'For Sale'}`

  const customMetaDesc =
    `${
      isGhostListing
        ? 'AVAILABLE TO ORDER: '
        : dealerListing?.listType === 'new'
        ? 'IN-STOCK: '
        : dealerListing?.listType === 'demo'
        ? 'NEAR-NEW: '
        : ''
    }` +
    `${isGhostListing ? 'New ' : dealerListing?.listType === 'new' ? 'New ' : ''}` + // needed these plus signs for readability and avoid new line in devtools
    `${dealerListing.year || ''}` +
    ` ${parseListingData(dealerListing?.makeName)}` +
    ` ${parseListingData(dealerListing?.modelName)},` +
    `${isGhostListing ? '' : ` Colour: ${parseListingData(dealerListing?.colour)},`}` +
    ` Fuel: ${parseListingData(dealerListing?.fuelType)},` +
    `${isGhostListing ? '' : ` KM: ${parseListingData(dealerListing?.odometer.toString())},`}` +
    ` Price: A$${dealerListing?.priceDriveAway}.` +
    `${isGhostListing ? '' : ` ${dealerSuburb} ${dealerState}.`}` +
    `${
      isGhostListing
        ? ' Order today.'
        : dealerListing?.listType === 'new'
        ? ' Drive away TODAY.'
        : ''
    }`

  const customPublishedTime = dealerListing.createdAt || ''
  const canonicalUri = isGhostListing
    ? `/cars-for-sale/car/g-${dealerListing.id || ''}/`
    : `/cars-for-sale/car/${dealerListing.id || ''}/`

  const firstImage =
    isArray(dealerListing.images) && !isEmpty(dealerListing.images) ? dealerListing.images[0] : {}

  const parsedImageData = getImageData(
    firstImage.srcUrl,
    {
      publicId: firstImage.publicId || '',
      cloudName: firstImage.cloudName || '',
      deliveryType: firstImage.deliveryType || ''
    },
    CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL
  )

  return {
    customTitle,
    customMetaDesc,
    customPublishedTime,
    canonicalUri,
    preloadImageData: parsedImageData.preloadImageData,
    customImageMeta: parsedImageData.customImageMeta
  }
}

export const getCarsForSaleListings = async (client, queryVariables) => {
  return await client.query({
    query: DEALER_LISTINGS,
    context: {
      listing: true
    },
    variables: queryVariables
  })
}

export const getListingsSpecs = async (client, listings) => {
  const { data: listingSpecs, errors: listingSpecsErrors } = await client.query({
    query: LISTING_CARD_SPECS,
    context: {
      tailpipe: true
    },
    variables: {
      query: { vehicleKey: { in: [...getVehicleKeys(listings?.results ?? [])] } }
    }
  })

  return { listingSpecs, listingSpecsErrors }
}

/**
 * Checks if need to use fallback listings for cars for sale section
 * @param {Object} listings Listing response
 * @param {Number} minCards Minimum Cards required for section
 */
export const isFallbackListings = (listings, minCards) => {
  const cardsRequired = minCards || CARS_FOR_SALE_SECTION_CARDS
  if (
    isEmpty(listings) ||
    !isArray(listings?.results) ||
    listings?.results?.length < cardsRequired
  ) {
    return true
  }
  return false
}

/**
 * Generates breadcrumbs for single listing page
 * @param {Object} dealerListing Listing Details
 * @returns {Array} Breadcrumb's list
 */
export const getSingleListingBreadCrumbs = (dealerListing, options = { showLast: false }) => {
  const {
    region: listingRegion,
    makeSlug,
    modelSlug,
    redbookVehicle,
    listType
  } = dealerListing || {}
  const listTypeSlug = listType ?? GHOST_LISTING_TYPE?.value
  const listTypeLabel = listType ? capitalize(listType) : GHOST_LISTING_TYPE.label
  const { regionName, regionSlug, state, stateSlug } = listingRegion || {}
  const { vehicleMake, vehicleModel } = redbookVehicle || {}

  const stateUrlSlug = !isEmpty(stateSlug) ? stateSlug : 'all'
  const regionUrlSlug = !isEmpty(regionSlug) ? regionSlug : 'all'

  const insertIf = (condition, elements) => (condition ? elements : [])

  const breadCrumbs = [
    //1. listType
    { label: listTypeLabel, filters: { listingType: [listTypeSlug] } },
    //2. state
    { label: state, filters: { listingType: [listTypeSlug], state: stateUrlSlug } },
    //3. region
    {
      label: regionName,
      filters: {
        listingType: [listTypeSlug],
        state: stateUrlSlug,
        region: regionUrlSlug
      }
    },
    //4. make
    {
      label: vehicleMake?.description,
      filters: {
        listingType: [listTypeSlug],
        state: stateUrlSlug,
        region: regionUrlSlug,
        make: makeSlug ?? vehicleMake?.slug
      }
    },
    //5. model
    {
      label: vehicleModel?.description,
      filters: {
        listingType: [listTypeSlug],
        state: stateUrlSlug,
        region: regionUrlSlug,
        make: makeSlug ?? vehicleMake?.slug,
        model: modelSlug ?? vehicleModel?.slug
      }
    }
  ].map(({ label, filters }) => getBreadCrumb(label ?? '', filters))

  return [
    { text: 'Home', url: '/' },
    { text: 'Marketplace', url: '/cars-for-sale/' },
    { text: 'Search Cars For Sale', url: '/cars-for-sale/search/' },
    ...breadCrumbs,
    ...insertIf(options.showLast, [{}])
  ].filter((breadCrumb) => !!breadCrumb) //only keep defined breadcrumbs
}

/**
 *Generates schema data for single listing page
 * @param {Object} dealerListing Listing Details
 * @param {Object} specs Listing specs
 * @param {Array} breadCrumbs Listing breadcrumbs
 * @returns {Object} Schema data for listing page
 */
export const getSingleListingSchemaData = (dealerListing, driveVariantData, breadCrumbs) => {
  const specs = transformSpecs(driveVariantData, dealerListing)
  let schemaData = {}

  if (dealerListing) {
    const make = dealerListing?.makeName
    const model = dealerListing?.modelName
    const variant = dealerListing?.variant
    const region = dealerListing?.region?.regionName ?? ''
    const state = dealerListing?.stateName ?? ''
    const location = !isEmpty(state) && !isEmpty(region) ? `${region}, ${state}` : 'Australia'
    const ghostListingMakeSlug = dealerListing?.redbookVehicle?.vehicleMake?.slug

    /** List out transformed schema image urls */
    const schemaImages = dealerListing?.images?.map((image) => {
      const transformedImage = getImageBySize(
        image.srcUrl,
        'CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL_SCHEMA',
        {
          cloudName: image?.cloudName,
          publicId: image?.publicId
        }
      )
      return transformedImage?.src
    })

    schemaData = {
      id: dealerListing?.id ?? 0,
      type: dealerListing?.listType ? dealerListing?.listType.toLowerCase() : '',
      imageUrl: dealerListing?.images?.[0] ?? '',
      publishAt: dealerListing?.createdAt ?? '',
      images: schemaImages ?? [],
      year: dealerListing?.year || '',
      make: make || '',
      makeSlug: dealerListing?.makeSlug ?? ghostListingMakeSlug ?? '',
      model: model || '',
      variant: variant || '',
      region,
      state,
      colour: dealerListing?.colour,
      doors: specs?.ownershipAndSafety?.doorNum || '',
      seats: specs?.ownershipAndSafety?.seatCapacity || '',
      fuelType: dealerListing?.fuelType ?? '',
      bodyType: dealerListing?.body ?? dealerListing?.bodyStyleDescription ?? '',
      name: `${dealerListing?.year} ${make} ${model} for sale in ${location}`,
      kerbWeight: dealerListing?.redbookVehicle?.kerbWeight ?? 0,
      odometer: dealerListing?.odometer ?? 0,
      priceDap: dealerListing?.priceDriveAway ?? dealerListing?.priceExcludingGovtCharges ?? 0,
      fuelCapacity: specs?.fuelAndEnviroment?.fuelCapacity ?? 0,
      altEngEngineType: specs?.engineAndPerformance?.altEngEngineType ?? '',
      driveCode: specs?.transmission?.driveCode ?? '',
      co2Combined: specs?.fuelAndEnviroment?.co2Combined ?? '',
      fuelCombined: specs?.fuelAndEnviroment?.fuelCombined ?? '',
      gearNum: specs?.transmission?.gearNum ?? '',
      engineType:
        dealerListing?.redbookVehicle?.engineTypeDescription ??
        specs?.engineAndPerformance?.engineType ??
        '',
      cylinders: specs?.engineAndPerformance?.cylinders ?? '',
      acceleration: specs?.engineAndPerformance?.acceleration ?? '',
      engineSize:
        dealerListing?.redbookVehicle?.engineSize ?? specs?.engineAndPerformance?.engineSize ?? '',
      torque: specs?.engineAndPerformance?.torque ?? 0,
      gearTypeDescription: specs?.transmission?.gearTypeDescription ?? '',
      vin: dealerListing?.vin ?? ''
    }
  }

  schemaData.breadCrumbs = Object.assign([], breadCrumbs)
  return schemaData
}

/**
 * Generates meta tags for single listing page
 * @param {Object} page Listing page data
 * @param {Object} dealerListing Listing Details
 * @returns {Object} Meta tags for listing page
 */
export const getSingleListingMetaTags = (page, dealerListing) => {
  const {
    makeName,
    modelName,
    year,
    fuelType,
    vehicleVfactsPrice,
    vehicleVfactsClass,
    vehicleVfactsSegment,
    priceDap,
    priceEgc
  } = dealerListing || {}
  const { dataLayer, targeting } = updateCFSPageTargetingAndDataLayer(
    {
      dataLayer: page?.dataLayer ?? [],
      targeting: page?.targeting ?? {}
    },
    {
      listingType: [dealerListing?.listType?.toLowerCase()],
      make: { name: makeName },
      model: { name: modelName },
      year: year,
      fuelType: fuelType,
      vehicleVfactsPrice,
      vehicleVfactsClass,
      vehicleVfactsSegment,
      price: priceDap ?? priceEgc,
      engineTypeDescription: dealerListing?.redbookVehicle?.engineTypeDescription
    }
  )

  return {
    rssFeed: page?.rssFeed ?? [],
    dataLayer,
    targeting
  }
}

/**
 * Get dealer listing label
 */
export const getListingType = (listingType) => {
  switch (upperCase(listingType)) {
    case 'NEW':
      return 'New'
    case 'USED':
      return 'Used'
    case 'DEMO':
      return 'Demo'
    default:
      return null
  }
}

export const checkListings = async (client, query) => {
  if (isEmpty(client) || isEmpty(query)) return false

  const { data, error } = await client.query({
    query: DEALER_LISTINGS_COUNT,
    context: {
      listing: true
    },
    variables: {
      where: JSON.stringify(query)
    }
  })
  if (!isEmpty(error)) {
    return false
  }
  const listingCount = data?.count?.pageInfo?.itemCount
  return isEmpty(listingCount) && isNumber(listingCount) && listingCount > 0
}

/**
 * Get listing images
 * @param {Array} listingImage Listing dealer images
 * @param {Array} redbookImages Redbook variant images
 * @param {string} type of listing
 * @returns {{images: Array, type: string}} Object of images
 */
export const getListingImages = (
  listingImages,
  redbookImages,
  listingType,
  ghostListing = false
) => {
  if (
    (NEW_LISTING_TYPES.includes(listingType?.toLowerCase()) || ghostListing) &&
    isEmpty(listingImages) &&
    !isEmpty(redbookImages)
  ) {
    /* returns rebook images when its new or demo and has dealer hasn't uploaded images */
    const photoViews = ['F', 'S', 'R', 'I']
    let variantPhotos = []

    photoViews.forEach((viewBy) => {
      const photoFound = getVariantPhotoByView({
        photos: redbookImages,
        viewPath: 'redbookPhoto.view',
        view: viewBy
      })
      !isEmpty(photoFound) && variantPhotos.push(photoFound)
    })

    /* if no photos found based on views return first 4 photos from variant */
    if (!variantPhotos.length) {
      variantPhotos = redbookImages.slice(0, 4)
    }

    const missingPhotosCount = photoViews.length - variantPhotos.length

    if (missingPhotosCount > 0) {
      /* filtersby image that is not already present in variant photos */
      const otherredbookImages = redbookImages.filter((variantImage) => {
        const hasImage = find(variantPhotos, { id: variantImage?.id })
        return !hasImage
      })

      /* extract the images that is needed to fill the missingPhotos count */
      const missingImages = otherredbookImages.slice(0, missingPhotosCount)

      /* finally add to the redbookImages filling the missing images */
      variantPhotos = [...variantPhotos, ...missingImages]
    }

    return {
      images: variantPhotos,
      type: 'redbook'
    }
  }

  /* for rest return listing images when dealer has uploaded image or when its not new or demo */
  return {
    images: listingImages,
    type: 'dealer'
  }
}

/**
 * @description Get primary Image from redbookImages
 * @param {Array} redbookImages Redbook variant images
 * @returns {image} Primary Image
 */
export const primaryImageFromRedbook = (redbookImages, wordpressImage = {}) => {
  if (isEmpty(redbookImages)) {
    return wordpressImage
  }
  // Check if "F" view image exists
  const frontImage = redbookImages.find((img) => img.redbookPhoto?.view === 'F')
  if (frontImage) {
    return frontImage
  }
  // Check if "S" view image exists (fallback 1)
  const sideImage = redbookImages.find((img) => img.redbookPhoto?.view === 'S')
  if (sideImage) {
    return sideImage
  }
  // Check if any Redbook image exists (fallback 2)
  const fallbackImage = redbookImages.find((img) => img.redbookPhoto)
  if (fallbackImage) {
    return fallbackImage
  }
  // Or else, return the first image in the array as the fallback
  return redbookImages[0]
}
