import { RefObject, useEffect } from 'react'

const useClickOutside = ({ onClickOutside, observeRef }: Props): void => {
  const handleClickOutside = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (!observeRef.current) return
    if (!observeRef.current?.contains(event.target as Node)) {
      onClickOutside && onClickOutside(event)
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

export default useClickOutside

type Props = {
  onClickOutside?: (event?: MouseEvent) => void
  observeRef: RefObject<HTMLDivElement>
}
