import { NUSED } from '@grille/types/listings'
import { getSingularOrPluralText } from './miscellaneous'

export * from './miscellaneous'

export const formatKms = (distance: number): string => {
  if (isNaN(distance)) {
    return ''
  }
  let kmsString = new Intl.NumberFormat().format(distance)

  return `${kmsString} ${getSingularOrPluralText(distance, 'km')}`
}

export const parseKms = (kmsString: string): number | null => {
  kmsString = kmsString
    .replace(/km[s]?/, '')
    .replace(/ /, '')
    .replace(/,/g, '')
    .replace(/\+/g, '')
  const parsedValue = parseInt(kmsString)
  return !isNaN(parsedValue) ? parsedValue : null
}

/**
 * Function to check if price is number and not 0
 * @param price {Number | Undefined}
 * @returns {boolean} Returns if price is valid number
 */
export const isValidPrice = (price: number | undefined | null): boolean => {
  return price !== null && isFinite(price ?? 0) && price !== 0
}

/**
 * Function to remove all characters from string except alphabet
 * @param  {NUSED | | null | string} string unclean string
 * @returns {string} Returns cleaned string
 */
export const cleanUpString = (string: NUSED | null | string): string => {
  return string?.replace(/[^a-zA-Z]/g, '') ?? ''
}
