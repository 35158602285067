import { FunctionComponent } from 'react'
import styles from './nused-banner.module.scss'
import cx from 'classnames'
import { LinkIcon, NusedBig } from '@grille/components/icons'
import { Color } from '@grille/constants/colors'
import Image from '@grille/components/common/image'
import Link, { linkDefaultProps } from '@grille/components/common/link'
import { NUSED_BANER_IMAGE } from '@grille/constants/nused'
import { ABOUT_NUSED_PAGE } from '@grille/constants/pages'

const NusedBanner: FunctionComponent<NusedBannerProps> = ({ link = ABOUT_NUSED_PAGE }) => {
  const cloudinary = NUSED_BANER_IMAGE?.cloudinary

  return (
    <div className={cx(styles['drive-nused-banner'])}>
      <div className={cx(styles['drive-nused-banner__wrapper'])}>
        <div className={cx(styles['drive-nused-banner__wrapper__title-link'])}>
          <NusedBig
            className={cx(styles['drive-nused-banner__wrapper__title-link__nused-logo'])}
            color={Color.white}
          />
          <Link {...linkDefaultProps} href={link}>
            <div className={cx(styles['drive-nused-banner__wrapper__title-link__external-link'])}>
              <span>Explore NUSED™</span>
              <LinkIcon color={Color.white} />
            </div>
          </Link>
        </div>
        <div className={cx(styles['drive-nused-banner__wrapper__description-image'])}>
          <p>The all-new way to shop new and quality used cars available to drive away now.</p>
          <Image
            sourceUrl={NUSED_BANER_IMAGE?.sourceUrl}
            cloudinary={cloudinary}
            loading='eager'
            sizeName='NUSED_BANNER_IMAGE'
          />
        </div>
      </div>
    </div>
  )
}

type NusedBannerProps = {
  link?: string
}

export default NusedBanner
